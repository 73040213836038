@import "../../../../styles/base/vars";
@import "../../../../styles/functions";
@import "../../../../styles/mixins";

.Logo {
  display: block;
  width: 147px;
  height: 32px;
}

.Aside {
  position: relative;

  .CollapseMenu {
    margin-right: -16px;
    background-color: #1d215b;
    border-radius: 6px;
  }

  .MainMenu {
    margin-top: rem(17);
  }

  .MainMenuMin {
    padding-left: 10px;

    .ListItemText,
    .ListItemArrow {
      display: none !important;
    }

    :global .MuiListItemButton-root {
      display: flex;
      justify-content: center;
      flex-grow: 0;
      width: 40px;
      height: 40px;
      border-radius: rem(6);

      > div {
        display: flex;
      }
    }

    :global .MuiListItemIcon-root {
      min-width: auto;
    }
  }

  .ListItemArrow {
    width: rem(10);
    height: rem(10);
    margin-right: rem(11);
    color: #4e527f;
  }

  :global .MuiListItemButton-root {
    border-radius: 0;
  }

  :global .MuiDivider-root {
    margin: rem(10) auto;
    background-color: #1d215b;
  }

  :global .MuiListItemText-inset {
    padding-left: 34px;
  }
}

.ListItemText :global .MuiTypography-root {
  font-weight: 600;
  font-size: rem(13);
  line-height: rem(16);
}

.AsideHeader {
  min-height: 60px;
}

body .DropdownList {
  width: max-content;
  margin-top: rem(-19);
  margin-left: rem(30);
  padding: rem(8) 0;
  background-color: #030848;
  border-radius: rem(8);

  :global .MuiListItemButton-root {
    padding: rem(8) rem(20);
    border-radius: 0;
  }

  .DropdownHeaderBox {
    background: none !important;
    opacity: 1;
    padding: rem(8) rem(20) 0;
  }

  .DropdownHeaderBox :global .MuiTypography-root {
    color: #9cabbd;
    font-weight: 600;
    font-size: rem(10);
    line-height: rem(16);
  }

  .DropdownItemsList :global .MuiIconButton-root {
    margin-left: rem(20);
  }

  .DropdownItemsList :global .MuiTypography-root {
    color: #fff;
  }

  .DropdownItemsList :global .active .MuiTypography-root {
    color: #6658ff;
  }
}

body .BackButton {
  width: rem(40);
  height: rem(40);
  margin-right: rem(-8);
  background-color: #1d215b;
  border-radius: rem(6);
}

:global .MobileDrawerNav {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .Search .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .SearchIconBox {
    color: #fff;
  }

  .Search .MuiOutlinedInput-input::placeholder {
    color: #fff;
  }

  .Search {
    width: auto;
    margin: rem(16) rem(16) rem(20);
    background: none;
    border: 1px solid rgba(#e8ecf1, 0.25);

    &:hover {
      background: none;
    }

    .MuiOutlinedInput-input {
      color: #fff;
    }

    .MuiOutlinedInput-input:focus::placeholder {
      color: transparent;
    }
  }

  .MuiListItemText-inset {
    padding-left: 34px;
  }

  .ListItemArrow {
    width: rem(10);
    height: rem(10);
    margin-right: rem(11);
    color: #4e527f;
  }

  :global .ListItemArrowDown {
    width: rem(10);
    height: rem(10);
  }
}

.Tooltip {
  padding-left: 4px;
}
