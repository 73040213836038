.Auth {
  margin-top: 4em;
}

.BackLink :global {
  .MuiButton-root {
    padding-right: 0;
    padding-left: 0;
    background: none;
  }
}
