@import '../../../../styles/base/vars';
@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.LoaderInfo,
.Info {
  color: #445277;
  font-size: rem(14);
  line-height: rem(24);
}

.LoaderInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: rem(296);
}

.Info {
  max-width: 358px;
  margin-top: rem(189);
  margin-bottom: rem(32);
}

.Text {
  margin-bottom: rem(32);
  font-size: rem(14);
  line-height: rem(24);
}

@include mq(phone) {
  .LoaderInfo {
    align-items: flex-start;
    margin-top: rem(40);
  }

  .Info {
    margin-top: rem(40);
  }
}
