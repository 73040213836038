@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf) {
  $src: null;

  $extmods: (
    eot: "?",
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $path-to-fonts: "/styles/fonts/";
    $full-path: $path-to-fonts + $name + "/" + $path + "/" + $path + "." + $extmod;
    $src: append($src, url(quote(#{$full-path})) format(quote(#{$format})), comma);
  }

  @font-face {
    font-weight: $weight;
    font-family: quote($name);
    font-style: $style;
    src: $src;
  }
}

@font-face {
  font-family: Inter;
  font-weight: 100 1000;
  font-style: normal;
  font-stretch: 25% 150%;
  font-display: fallback;
  src: url("../fonts/Inter/Inter-VariableFont.ttf") format("truetype-variations"),
    url("../fonts/Inter/Inter-VariableFont.woff2") format("woff2-variations");
}
