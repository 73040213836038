@import "../../../../styles/base/vars";
@import "../../../../styles/functions";
@import "../../../../styles/mixins";

.Profile {
  padding-top: rem(6);
  color: #212b36;
  text-transform: none;

  .Name {
    color: #212b36;
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(19);
  }

  .RoleAndID {
    > span {
      color: #9cabbd;
      font-size: rem(12);
      line-height: rem(19);
    }

    :first-child {
      margin-right: rem(4);
    }
  }
}

.ProfileBox {
  :global .MuiButton-endIcon {
    position: absolute;
    right: rem(12);
  }

  .ProfileName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.UserIcon {
  margin-right: rem(8);
  padding: rem(6);
  font-size: rem(16);
  background-color: #f4f6fa;
  border-radius: 4px;
  box-sizing: content-box;
}

.Modal {
  :global .MuiBackdrop-root {
    background: transparent;
  }

  .Divider {
    margin: 6px 0 12px;
    border-color: #e8ecf1;
  }

  li.MenuItem {
    background-color: inherit !important;
    opacity: 1;
  }

  .SignOutBox {
    background-color: inherit !important;
  }

  .SignOut {
    width: 100%;
    min-height: 40px;
    margin-top: rem(12);
    padding: 0;

    :global .MuiButton-startIcon svg.MuiSvgIcon-root {
      margin-right: 0;
      color: #212b36;
    }
  }
}
