@import '../../../styles/base/vars';
@import '../../../styles/functions';
@import '../../../styles/mixins';

:root {
  --authLayout-max-width: 486px;
  --authLayout-padding-r-l: #{rem(16)};
}

.Container {
  min-height: 100vh;
  display: flex;
  position: relative;
}

.Banner {
  height: 100%;
  background: #6658ff no-repeat center/cover;
}

.Content {
  width: 100%;
  min-height: 100vh;
  max-width: var(--authLayout-max-width);
  margin: 0 auto;
  padding: rem(95) var(--authLayout-padding-r-l) rem(24);
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Logo {
  display: block;
  width: 215px;
  height: 47px;
}

@include mq(phone) {
  .Container {
    display: block;
    margin-bottom: 0;
  }

  .Content {
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .Logo {
    width: 132px;
    height: 29px;
  }
}
