.ScrollbarsCustom-Track {
  background: transparent !important;
}

.ScrollbarsCustom-TrackY {
  width: 3px !important;
  right: 3px !important;
}

.ScrollbarsCustom-TrackX {
  height: 3px !important;
  bottom: 3px !important;
}

.ScrollbarsCustom-Thumb {
  background: #9cabbd !important;
}
