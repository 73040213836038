@import "./vars";
@import "../functions";
@import "../mixins";
@import "../modules";

// Global base styles. Customize it depends on your project.
body {
  min-width: $app-min-width;

  @include typography($color-base, font-weight("Regular"), $font-size-base, $font-family-base, $line-height-base);

  background-color: $body-bg;

  * {
    font-family: inherit !important;
  }
}

.Heading {
  @include typography($title-color-base);
}

@each $title, $size in $title-sizes {
  .Heading#{capitalize($title)} {
    font-size: $size;
  }
}

.HeadingH1 {
  margin-bottom: 12px;
  font-weight: 400;
  line-height: 44px;
}

.HeadingH2 {
  margin-bottom: 32px;
  font-weight: 500;
  line-height: 27px;
}

.HeadingH3 {
  margin-bottom: 32px;
}

p {
  color: $color-base;
}

a {
  text-decoration: none;
}

a.MuiTypography-root {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

:focus-visible {
  outline: none;
}

body {
  .MuiInputBase-root {
    &.Mui-error .MuiInputAdornment-positionStart,
    &.Mui-error .MuiInputAdornment-positionEnd {
      z-index: 1;
    }

    &.Mui-error .MuiInputBase-input {
      z-index: 1;
      color: #f13572;
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: #f13572;
      background: linear-gradient(0, rgba(241, 53, 114, 0.05), rgba(241, 53, 114, 0.05)), #fff;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
    }
  }

  .MuiTooltip-popper {
    z-index: 1300;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #212b36 !important;
  }

  .MuiInputLabel-root {
    margin-bottom: 6px;
    color: #9cabbd;
    font-weight: 700;
    font-size: 9px;
    line-height: 9px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &.Mui-error {
      color: #f13572;
    }
  }

  .MuiOutlinedInput-root.Mui-disabled {
    background-color: #f9fafb;
  }

  .MuiOutlinedInput-root .Mui-disabled {
    -webkit-text-fill-color: #9cabbd;
    color: #9cabbd;
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #e8ecf1;
  }

  .ButtonActive {
    color: #6658ff;
    border-color: #6658ff;
  }

  .MuiLoadingButton-loading {
    min-width: 100px;
  }

  .Link {
    color: #9cabbd;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }

  .LinkColorPrimary {
    color: #6658ff;
  }

  .LinkColorSecondary {
    color: #445277;
  }

  .Text {
    color: #445277;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .MuiFormControlLabel-root .MuiTypography-root {
    font-size: 14px;
    line-height: 24px;
  }

  .MuiFormControlLabel-root + .Link {
    position: relative;
    top: 1px;
    margin-left: -11px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background: none;
  }

  .MuiRadio-root {
    padding: 4px 8px;
  }

  .MuiGrid-root .MuiTextField-root {
    width: 100%;
  }

  .MuiSelect-root {
    .SelectPlaceholder {
      color: #9cabbd;
    }

    &.Mui-error {
      svg {
        color: #f13572;
      }
    }

    .MuiSelect-select[aria-expanded="true"] ~ svg {
      transform: rotate(180deg);
    }
  }

  .MuiMenuItem-root {
    padding: rem(6) rem(16);
    font-weight: normal;
    font-size: rem(14);
    line-height: rem(24);
    text-transform: none;
    border-radius: 0;

    &.Mui-selected {
      background-color: #f4f6fa;
    }
  }

  .MuiCheckbox-root {
    margin-left: 2px;
  }

  .MuiBadge-badge {
    top: calc(-50% + 4px);
    right: -50%;
    min-width: 15px;
    height: 15px;
    padding: 0 4px;
    font-weight: 600;
    font-size: 10px;
    line-height: 1px;
    background-color: #1d215b;
    border-radius: 50%;
  }

  .MuiChip-root {
    font-size: rem(12);
  }

  .MuiChip-colorDefault {
    color: #9cabbd;
    background: rgba(156, 171, 189, 0.1);
  }

  .MuiChip-colorSuccess {
    color: #01ab55;
    background: rgba(1, 171, 85, 0.1);
  }

  .MuiChip-colorError {
    color: #f13572;
    background: rgba(241, 53, 114, 0.1);
  }

  input {
    border-color: #6658ff;
    box-sizing: border-box;
    -webkit-appearance: none;

    &:focus {
      border-color: #6658ff;
    }

    &:disabled {
      color: #9cabbd;
      background-color: #f9fafb;
    }
  }

  legend.MuiFormLabel-root {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit;
    transition: all 0s 50000s;
    box-shadow: 0 0 0 1000px #fff inset;
  }
}
