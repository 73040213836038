body {
  .__react_component_tooltip {
    padding: rem(8) rem(12);
    color: #9cabbd;
    font-weight: 600;
    font-size: rem(10);
    line-height: rem(16);
    background-color: #030848;
    box-shadow: 0 2px 8px rgba(3, 8, 72, 0.25);
    border-radius: 4px;
    text-transform: uppercase;

    &::after {
      width: 6px;
      height: 16px;
      left: -6px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='16' viewBox='0 0 6 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.41421 2.82843L0.292893 6.94975C-0.0976311 7.34027 -0.0976311 7.97344 0.292893 8.36396L4.41421 12.4853C5.16436 13.2354 5.58579 14.2528 5.58579 15.3137V0C5.58579 1.06087 5.16436 2.07828 4.41421 2.82843Z' fill='%23030848'/%3E%3C/svg%3E%0A");
      border: none !important;
    }

    &.place-right {
      margin-left: rem(21);

      &::after {
        margin-top: -8px;
      }
    }
  }
}
