@import "../../../../styles/base/vars";
@import "../../../../styles/functions";
@import "../../../../styles/mixins";

.DialogCheckbox {
  margin-top: rem(32);

  :global .MuiFormControlLabel-root {
    align-items: flex-start;
    margin-right: 0;
    text-align: left;
  }

  :global .MuiCheckbox-root {
    margin-top: rem(-10);
  }
}

.LogoutDialog {
  max-width: 484px !important;
}

@include mq(phone) {
  :global .MuiCheckbox-root {
    margin-top: -9px;
  }
}
