@import '../../../styles/base/vars';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.Tooltip {
  background-color: #030848 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: rem(12) !important;
  line-height: 1rem !important;
  padding: 0.5rem 0.75rem !important;
  border-radius: 4px;
  white-space: pre-wrap;
}
