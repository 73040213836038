@import "../base/vars";
@import "../functions";
@import "../mixins";

.Header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1001;
}

.ButtonBurger {
  width: rem(40);
  height: rem(40);
}

.CollapseMenu {
  width: rem(40);
  height: rem(40);
}

.ButtonIcon {
  border: 1px solid #e8ecf1 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  width: rem(36);
  height: rem(36);
}

body {
  .BellIcon {
    margin-right: rem(16);
  }

  .HelpIcon {
    margin-right: rem(12);
  }

  .SettingsIcon {
    margin-right: rem(19);
  }
}

@media (max-width: 899px) {
  .ProfileButton {
    min-width: auto !important;
    width: rem(40);
    height: rem(40);
    padding: 0 !important;
    background-color: #fff !important;
    border-radius: rem(8) !important;

    :global .MuiButton-startIcon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(16);
      height: rem(16);
      margin: 0;
      border-radius: 4px;
      box-sizing: content-box;

      svg {
        position: absolute;
        padding: rem(5);
        border-radius: 4px;
      }
    }
  }

  body {
    .BellIcon,
    .UserIcon {
      width: rem(40);
      height: rem(40);
      background-color: #fff;
    }

    .BellIcon {
      margin-right: rem(8);
    }
  }

  .UserIcon {
    color: #212b36;
  }

  .CollapseMenu {
    width: rem(40);
    height: rem(40);
    background-color: #1d215b;
    border-radius: rem(6);
    margin-right: rem(16);
  }

  :global .MobileDrawer {
    .MuiPaper-root {
      border-top-right-radius: rem(12);
      border-bottom-right-radius: rem(12);
    }
  }

  :global .MobileDrawerNav {
    .MuiListItemButton-root {
      border-radius: 0;
    }
  }
}

.Modal {
  :global .MuiBackdrop-root {
    background: transparent;
  }

  :global .MuiMenu-list {
    min-width: 200px;
  }

  .Divider {
    margin: 6px 0 12px;
    border-color: #e8ecf1;
  }

  li.MenuItem {
    background-color: inherit !important;
    opacity: 1;
  }

  .SignOutBox {
    background-color: inherit !important;
  }

  .SignOut {
    width: 100%;
    min-height: 40px;
    margin-top: rem(12);
    padding: 0;

    :global .MuiButton-startIcon svg.MuiSvgIcon-root {
      margin-right: 0;
      color: #212b36;
    }
  }

  @media (max-width: 899px) {
    :global .MuiMenu-list {
      min-width: 296px;
    }

    :global .MuiMenuItem-root {
      padding-top: rem(8);
      padding-bottom: rem(8);
      color: #212b36;
    }

    :global .MuiPopover-paper {
      box-shadow: 0 4px 14px rgba(3, 8, 72, 0.1);
    }

    .UserIcon,
    .HelpIcon,
    .SettingsIcon {
      width: rem(28);
      height: rem(28);
      margin-right: rem(8);
      background-color: #f4f6fa;
      border-radius: rem(4);
    }
  }

  @include mq(phone) {
    :global .MuiMenu-list {
      min-width: calc(100vw - 24px);
    }
  }
}

.Profile {
  padding-top: rem(6);
  color: #212b36;
  text-transform: none;

  .Name {
    color: #212b36;
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(19);
  }

  .RoleAndID {
    > span {
      color: #9cabbd;
      font-size: rem(12);
      line-height: rem(19);
    }

    :first-child {
      margin-right: rem(4);
    }
  }
}
