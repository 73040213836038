@import "styles/base";
@import "styles/functions";
@import "styles/mixins";

/**
For use Ant Design install dependencies
```
$ npm install antd
```
Then import one of `.css` theme files:

Default Theme
`@import '~antd/dist/antd.compact.min.css';`

Dark Theme
`@import "~antd/dist/antd.dark.min.css";`

Compact Theme
`@import "~antd/dist/antd.compact.min.css";`

Then use components
 */

/**
For use Material UI install dependencies
```
$ npm install @material-ui/core
```
Then just use components
 */

.MuiPaper-root.js-highlight-panel {
  transition: background 1s ease;
}

.MuiPaper-root.success-highlight {
  background-color: limegreen;
}

.MuiPaper-root.error-highlight {
  background-color: red;
}
